<template>
  <v-form ref="form" class="custom__card" lazy-validation>
    <v-container fluid>
      <v-card class="mt-1 pa-4">
        <v-row>
          <v-col cols="12" sm="4" class="pb-0 pb-sm-2">
            <h4>{{$_strings.order.NO_CARGO}}</h4>
          </v-col>
          <v-col cols="12" sm="4" class="pt-0 pt-sm-2">
            <h4>{{shipment && shipment.cargoTitle}}</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0 pb-sm-2">
            <h4>{{$_strings.order.ORDER_NUMBER}}</h4>
          </v-col>
          <v-col cols="12" sm="4" class="pt-0 pt-sm-2">
            <h4>{{shipment.shipment && shipment.shipment.shipmentsTitle}}</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0 pb-sm-2">
            <span>{{$_strings.order.BASP_REASON}}
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-autocomplete
              :loading="isLoadingListBaspReason"
              v-model="tempBaspTitle"
              :items="itemsBasp"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.BASP_REASON)]"
              outlined
              dense
              item-text="reason"
              item-value="id"
              @change="handleBaspTitle"
              @input.native="getListBaspReason"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0 pb-sm-2">
            <span>{{$_strings.order.EXPLANATION_OF_REASON_BASP}}</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-textarea
              no-resize
              outlined
              dense
              v-model="form.baspNotes"
              :counter="255"
              :rules="[(v) => rulesNotes(v, false, 255, $_strings.companyInformation.COMPANY_ADDITIONAL_INFO_INPUT_LABEL)]"
              rows="5"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0 pb-sm-2">
            <span>{{$_strings.order.TYPE_OF_GOODS}}</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              readonly
              v-model="form.baspCategory"
              outlined
              filled
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0 pb-sm-2">
            <span>{{$_strings.order.DESCRIPTION}}</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="form.note"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" sm="6" md="4">
            <v-row>
              <v-col class="pb-0 pt-0" cols="12">
                <span>{{$_strings.order.PROOF_OF_DIFFERENCE_IN_ACCEPTANCE}}
                  <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
                </span>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <div class="img-box">
                  <img v-if="form.baspPhoto[0]" :src="form.baspPhoto[0]" alt="">
                </div>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-file-input
                  :clearable="false"
                  show-size
                  ref="file_upload"
                  @change="($event) => setFileInput($event, 'baspPhoto')"
                  :disabled="isLoading"
                  label="Pilih Foto"
                  type="file"
                  accept="image/*"
                  outlined
                  dense
                  prepend-icon=""
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.PROOF_OF_DIFFERENCE_IN_ACCEPTANCE)]"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip
                      style="w-50"
                      small
                      label
                      color="primary"
                    >
                      {{ text.length > 20 ? text.substr(0, 20) : text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-row>
              <v-col class="pb-0 pt-0" cols="12">
                <span>{{$_strings.order.DOCUMENTATION}}</span>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <div class="img-box">
                  <img v-if="form.documentationPhoto[0]" :src="form.documentationPhoto[0]" alt="">
                </div>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-file-input
                  :clearable="false"
                  show-size
                  ref="file_upload"
                  @change="($event) => setFileInput($event, 'documentationPhoto')"
                  :disabled="isLoading"
                  label="Pilih Foto"
                  type="file"
                  accept="image/*"
                  outlined
                  dense
                  prepend-icon=""
                >
                  <template v-slot:selection="{ text }">
                    <v-chip
                      style="w-50"
                      small
                      label
                      color="primary"
                    >
                      {{ text.length > 20 ? text.substr(0, 20) : text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn
              small
              width="120"
              color="red"
              class="white--text mr-2"
              @click="$router.go(-1)"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
            <v-btn
              small
              width="120"
              color="primary"
              @click="submit"
              :disabled="isLoading"
            >
              {{$_strings.order.SEND_BASP}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
import { rulesNotes } from '../../helper/commonHelpers';

export default {
  mounted() {
    const { shipment } = this.$route.params;
    if (!shipment) return this.$router.replace('/main/pesanan/list/process/');
    this.getListBaspReason();
    this.shipment = shipment;
    this.form.shipmentsId = shipment.shipment.shipmentsId;
    this.form.baspCategory = `${shipment.shipment.categoryName} - ${shipment.shipment.subCategoryName}`;
  },
  data() {
    const date = new Date();
    return {
      isLoadingListBaspReason: false,
      isLoading: false,
      dialog: false,
      title: '',
      shipmentCargoId: '',
      shipment: {}, // from page order
      itemsBasp: [],
      tempBaspTitle: '',
      form: {
        shipmentsId: null,
        shipmentsDetailId: null,
        locationId: 0,
        isNeedConfirm: false,
        baspTitle: '',
        baspNotes: '',
        baspCategory: '',
        note: '',
        baspPhoto: [],
        documentationPhoto: [],
        receiverName: '',
        datetimeTz: date.toString().split('+')[1].substr(1, 1),
      },
    };
  },
  methods: {
    rulesNotes,
    fileUploadValidation(event, fileSizeMin = 1024000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error(`Ukuran file tidak boleh lebih dari ${fileSizeMin / 1024000}MB.`);
        return false;
      }
      return true;
    },
    setFileInput(file, type) {
      const valid = this.fileUploadValidation(file);
      if (!valid) {
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form[type] = [e.target.result];
      };
      reader.readAsDataURL(file);
    },
    async getListBaspReason(event) {
      const filters = {
        page: 0,
        size: 20,
        type: 'qIn(basp)',
        sort: 'name,desc',
      };
      if (event) {
        filters.name = `qLike(${event.target.value})`;
      }
      this.isLoadingListBaspReason = true;
      try {
        const res = await this.$_services.order.baspReason(filters);
        this.itemsBasp = res.data.contents;
      } finally {
        this.isLoadingListBaspReason = false;
      }
    },
    handleBaspTitle(idBaspReason) {
      const findBasp = this.itemsBasp.find((item) => item.id === idBaspReason);
      this.form.baspTitle = findBasp.reason;
      this.form.isNeedConfirm = findBasp.needConfirm;
    },
    async submit() {
      const { shipment } = this.$route.params;
      const { shipmentsCargoId } = shipment.shipment;

      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        this.$dialog.notify.error('Mohon lengkapi form');
        return;
      }
      try {
        this.$root.$loading.show();
        await this.$_services.order.submitBasp(this.form, shipmentsCargoId);
        this.$dialog.notify.success('Berhasil');
        this.$router.go(-1);
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.img-box{
  margin-bottom: 2px;
  display: block;
  width: 100%;
  border: 1px solid rgb(189, 183, 183);
  height: 120px;
  img {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
